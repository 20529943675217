import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryCard = _resolveComponent("CategoryCard")!
  const _component_ContentCard = _resolveComponent("ContentCard")!
  const _component_Template15 = _resolveComponent("Template15")!

  return (_openBlock(), _createBlock(_component_Template15, null, {
    "left-column": _withCtx(() => [
      _createVNode(_component_CategoryCard)
    ]),
    "center-column": _withCtx(() => [
      _createVNode(_component_ContentCard)
    ]),
    _: 1
  }))
}