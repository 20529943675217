
import { defineComponent } from "vue";
import CategoryCard from "@/components/MessageBoard/CategoryCard.vue";
import ContentCard from "@/components/MessageBoard/ContentCard.vue";
import Template15 from "@/views/templates/Template15.vue";

export default defineComponent({
  name: "Message",
  components: {
    Template15,
    CategoryCard,
    ContentCard,
  },
});
