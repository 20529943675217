
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import Forum from "@/components/MessageBoard/Forum.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    Forum,
  },
  mixins: [mixins],
  data() {
    return {
      isLoading: true,
      teacherOptions: [] as Array<{
        cnName: string;
        id: number;
        isTalk: boolean;
        photo: string;
        foreignName: string | null;
      }>,
      langOptions: ["全部"],
      activeTeacher: 0,
      activeLang: 0,
      content: [],
    };
  },
  methods: {
    // 選取教師
    selectTeacher(id: number) {
      this.activeTeacher = id;
    },
  },
  mounted() {
    // 取得該學生可使用語言
    axios
      .get(`${serverUrl}common/subject`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          for (let key in res.data.data.subject) {
            this.langOptions.push(this.setLangCh2(key));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // 取得教師名單
    axios
      .get(`${serverUrl}message_board`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          // 由於 API 尚未加入 foreignName ，因此先在這邊將其處理成 Null ，以便畫面顯示判斷
          // 如果沒有外文名稱時，顯示 cnName ，有外文名稱時則顯示 foreignName
          res.data.data.teachers.forEach(function (item: any, index: number) {
            if (item.foreignName === undefined) {
              item.foreignName = null;
            }
          });
          this.teacherOptions = res.data.data.teachers;
          if (this.teacherOptions.length > 0) {
            this.activeTeacher = this.teacherOptions[0].id;
            this.isLoading = false;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
