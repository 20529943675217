
import { defineComponent, HtmlHTMLAttributes, PropType } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";
import { Modal } from "bootstrap";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

interface Content {
  content: string | null;
  file: string | null;
  photo: string;
  role: string;
  time: number;
  user: string;
}

interface FormattedContent extends Content {
  formattedTime: Array<string | boolean>;
}

interface TeacherOptions {
  cnName: string;
  id: number;
  isTalk: boolean;
  photo: string;
}

export default defineComponent({
  mixins: [mixins],
  props: {
    activeTeacher: {
      type: Number,
      required: true,
    },
    activeLang: {
      type: Number,
      required: false,
    },
    langOptions: {
      type: Array,
      required: false,
    },
    teacherOptions: {
      type: Array as PropType<Array<TeacherOptions>> | [],
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingUser: true,
      isLoadingFile: true,
      isLoadingMobile: true,
      isSendingMsg: false,
      activeLangHere: this.activeLang !== undefined ? this.activeLang : 0,
      activeTeacherHere:
        this.activeTeacher !== undefined ? this.activeTeacher : 0,
      message: "",
      content: [] as Array<{
        content: string | null;
        file: string | null;
        photo: string;
        role: string;
        time: number;
        user: string;
        foreignName: string | null;
      }>,
      contentMobile: [] as Array<{
        content: string | null;
        file: string | null;
        photo: string;
        role: string;
        time: number;
        user: string;
        foreignName: string | null;
      }>,
      timeArray: [] as Array<string>,
      user: {
        name: "",
        photo: "",
      },
      file: "",
      filename: "",
      filenameCoded: null as null | string,
    };
  },
  methods: {
    // 整理對話框顯示時間
    formatTime(timestamp: number) {
      function formatMonthDate(time: number) {
        if (time.toString().length == 1) {
          return `0${time}`;
        } else {
          return time;
        }
      }

      let d = new Date(timestamp * 1000);
      let checkIfExisted = `${d.getFullYear()}-${
        d.getMonth() + 1
      }-${d.getDate()}`;
      const dateMap = ["一", "二", "三", "四", "五", "六", "日"];

      if (this.timeArray.includes(checkIfExisted) === false) {
        this.timeArray.push(checkIfExisted);
        return [
          true,
          `${formatMonthDate(d.getMonth() + 1)}月${formatMonthDate(
            d.getDate()
          )}日 週${dateMap[d.getDay() - 1]} ${d.getHours()}:${
            d.getMinutes() > 10 ? d.getMinutes() : "0" + d.getMinutes()
          }`,
        ];
      } else {
        return [
          false,
          `${formatMonthDate(d.getMonth() + 1)}月${formatMonthDate(
            d.getDate()
          )}日 週${dateMap[d.getDay() - 1]} ${d.getHours()}:${
            d.getMinutes() > 10 ? d.getMinutes() : "0" + d.getMinutes()
          }`,
        ];
      }
    },
    // 整理整包對話內容格式
    formatContent(content: Array<Content>) {
      this.timeArray = [] as Array<string>;
      let formattedContent = [] as Array<any>;

      if (content.length > 0) {
        content.forEach((element) => {
          let testNewContent = {} as FormattedContent;
          testNewContent.content = element.content;
          testNewContent.file = element.file;
          testNewContent.photo = element.photo;
          testNewContent.role = element.role;
          testNewContent.time = element.time;
          testNewContent.user = element.user;
          testNewContent.formattedTime = this.formatTime(element.time);
          formattedContent.push(testNewContent);
        });
      }
      return formattedContent;
    },
    openChat(teacherId: number) {
      this.removeFile();
      if (this.activeTeacher !== 0) {
        this.isLoadingMobile = true;
        axios
          .get(`${serverUrl}message_board/${teacherId}`, {
            headers: {
              Authorization: loginToken,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              this.contentMobile = this.formatContent(
                res.data.data.messageBoard
              );
              this.isLoadingMobile = false;
            }
          })
          // .then((res) => {
          //   const target = document.getElementById(
          //     `dialogue-scroller-${teacherId}`
          //   )! as HTMLElement;
          //   target.scrollTop = target.scrollHeight;
          // })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.isLoadingMobile = false;
      }
    },
    // 手機版展開對話框
    // openChat(teacherId: number) {
    // this.activeTeacherHere = teacherId;
    // Open Chat
    // Bus.emit("openMessageBoardDialogue", this.activeTeacherHere);
    // },
    // 開啟外部連結
    openUrl(link: string) {
      window.open(link);
    },
    openFile(file: string | null) {
      window.open(file!);
    },
    removeFile() {
      this.file = "";
      this.filename = "";
      this.filenameCoded = null;

    },
    handleFileUpload(e: any) {
      this.isLoadingFile = true;

      const file = e.target.files.item(0);
      this.filename = file.name;
      // console.log(this.filename);
      const reader = new FileReader();
      reader.addEventListener("load", this.fileLoaded);
      reader.readAsDataURL(file);

      // 呼叫API
      var postforms = new FormData();
      postforms.append("type", "1");
      postforms.append("file", file);
      var config = {
        headers: {
          Authorization: loginToken,
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(`${serverUrl}common/file`, postforms, config)
        .then((res) => {
          if (res.status == 200) {
            this.filenameCoded = res.data.data.fileName;
            this.isLoadingFile = false;
            // console.log(this.filenameCoded);
          }
        })
        .catch((error) => {
          console.error(error);
          Bus.emit("openAlertModal", {
            type: "warning",
            title: ["Warning!"],
            content: "檔案大小超過 1MB ，或是網路異常。",
            btnText: "關閉",
          });
          this.isLoadingFile = false;
          this.removeFile();
        });
    },
    fileLoaded(e: any) {
      this.file = e.target.result;
    },
    uploadFile() {
      axios
        .post(
          `${serverUrl}common/file`,
          {
            type: 1,
            file: this.filenameCoded,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendMsg() {
      if (this.isSendingMsg === true) {
        return;
      }
      this.isSendingMsg = true;
      axios
        .post(
          `${serverUrl}message_board/${this.activeTeacher}`,
          {
            fileName: this.filenameCoded,
            content: this.message,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.isLoading = true;
            this.isSendingMsg = false;
            axios
              .get(`${serverUrl}message_board/${this.activeTeacher}`, {
                headers: {
                  Authorization: loginToken,
                },
              })
              .then((res) => {
                if (res.status == 200) {
                  this.content = res.data.data.messageBoard;
                  this.isLoading = false;
                }
              })
              .catch((error) => {
                console.error(error);
              });

            this.message = ""; // 清空輸入
            this.removeFile(); // 清空檔案
          }
        })
        .catch((error) => {
          this.isSendingMsg = false;
          if (error.response.status == 422) {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["文字訊息請勿留空"],
              content: "訊息傳送失敗，請重新嘗試",
              btnText: "關閉",
            });
          } else {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["系統異常"],
              content: "訊息傳送失敗，請重新嘗試",
              btnText: "關閉",
            });
          }
          this.message = ""; // 清空輸入
          console.error(error);
        });
    },
    sendMsgMobile(teacherId: number) {
      if (this.isSendingMsg === true) {
        return;
      }
      this.isSendingMsg = true;
      axios
        .post(
          `${serverUrl}message_board/${teacherId}`,
          {
            fileName: this.filenameCoded,
            content: this.message,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.isLoadingMobile = true;
            axios
              .get(`${serverUrl}message_board/${teacherId}`, {
                headers: {
                  Authorization: loginToken,
                },
              })
              .then((res) => {
                if (res.status == 200) {
                  this.contentMobile = this.formatContent(
                    res.data.data.messageBoard
                  );
                  this.isLoadingMobile = false;
                  this.isSendingMsg = false;
                }
              })
              .catch((error) => {
                console.error(error);
              });

            this.message = ""; // 清空輸入
            this.removeFile(); // 清空檔案
          }
        })
        .catch((error) => {
          this.isSendingMsg = false;
          if (error.response.status == 422) {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["文字訊息請勿留空"],
              content: "訊息傳送失敗，請重新嘗試",
              btnText: "關閉",
            });
          } else {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["系統異常"],
              content: "訊息傳送失敗，請重新嘗試",
              btnText: "關閉",
            });
          }
          this.message = ""; // 清空輸入
          console.error(error);
        });
    },
  },
  watch: {
    activeTeacherHere() {
      if (this.activeTeacher !== 0) {
        this.isLoading = true;
        this.isLoadingUser = true;
        this.removeFile();
        // 取得對話內容
        axios
          .get(`${serverUrl}message_board/${this.activeTeacher}`, {
            headers: {
              Authorization: loginToken,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              this.content = res.data.data.messageBoard;
              // console.log("Here");
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });

        // 取得該使用者資訊
        axios
          .get(`${serverUrl}personal`, {
            headers: {
              Authorization: loginToken,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              this.user.name = res.data.data.user.name;
              this.user.photo = res.data.data.user.photo;
              this.isLoadingUser = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    activeTeacher() {
      if (this.activeTeacher !== 0) {
        this.isLoading = true;
        this.isLoadingUser = true;
        this.removeFile();
        // 取得對話內容
        axios
          .get(`${serverUrl}message_board/${this.activeTeacher}`, {
            headers: {
              Authorization: loginToken,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              this.content = res.data.data.messageBoard;
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });

        // 取得該使用者資訊
        axios
          .get(`${serverUrl}personal`, {
            headers: {
              Authorization: loginToken,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              this.user.name = res.data.data.user.name;
              this.user.photo = res.data.data.user.photo;
              this.isLoadingUser = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // console.log(this.activeTeacher);
      }
    },
  },
  mounted() {
    // 取得對話內容
    if (this.activeTeacher !== 0) {
      axios
        .get(`${serverUrl}message_board/${this.activeTeacher}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.content = this.formatContent(res.data.data.messageBoard);
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.isLoading = false;
    }

    // 取得該使用者資訊
    axios
      .get(`${serverUrl}personal`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.user.name = res.data.data.user.name;
          this.user.photo = res.data.data.user.photo;
          this.isLoadingUser = false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
